import React, { useState } from "react";
import logo from "../../assets/logo.png";
import Modal from "../commons/Modal";

const Header = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  return (
    <header className="fixed top-0 left-0 w-full p-6 bg-[#000064] border-b-4 border-[#F499B8] z-50 flex justify-between items-center">
      <img src={logo} alt="logo" width="250px" />
      <div className="mr-4 text-white text-right">
        <span
          className="font-['Klarheit_Grotesk'] text-lg normal-case font-light leading-normal tracking-tighter"
          onClick={toggleModal}
        >
          Official Rules
        </span>
      </div>
      {showModal && (
        <Modal onClose={toggleModal}>
          <div className="custom-scrollbar text-[#000064] font-semibold text-xl p-4 overflow-auto h-full">
            <p className="mb-4">
              • Explanation of the “no purchase necessary” rule which is a
              fundamental principle of sweepstakes in the US.
            </p>
            <p className="mb-4">
              • Distinction between sweepstakes, contests, and lotteries:
              Sweepstakes are chance-based promotions where winners are randomly
              drawn from among eligible entries. Contests, on the other hand,
              involve some element of skill, talent, or merit, and winners are
              chosen based on their performance. Lotteries, which are heavily
              regulated and restricted, differ from sweepstakes and contests, as
              they involve participants paying for a chance to win.
            </p>
            <p className="mb-4">
              • Federal has no rules regarding age and residence restrictions,
              however to participate in sweepstakes in New York, you must be at
              least 18 years old. 
            </p>
            <p className="mb-4">
              • Federal liability disclaimers and requirements: Sweepstakes
              sponsors must include certain disclaimers in their promotional
              materials to avoid legal issues. These disclaimers often state
              that the sweepstakes is void where prohibited and outline the
              sponsor’s responsibilities regarding prize fulfillment and winner
              selection.
            </p>
            <p className="mb-4">
              • State-level liability rules and regulations: New York has
              consumer protection laws that apply to sweepstakes and other
              promotions.Sponsors must provide clear and accurate information
              about the rules, eligibility criteria, and odds of winning to the
              participants.
            </p>
            <p className="mb-4">
              • New York requires sweepstakes with over certain prize values to
              be registered and bonded and delivered with sweepstakes
              fulfillment restrictions. 
            </p>
          </div>
        </Modal>
      )}
    </header>
  );
};

export default Header;
