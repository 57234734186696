import React from "react";

export default function Button({ type = "button", style, ...rest }) {
  return (
    <button
      type={type}
      style={{ ...style }}
      className={
        "inline-flex items-center justify-center py-2 btn " +
        (rest.className
          ? rest.className
          : "rounded-tl-[30px] rounded-br-[30px]") +
        (rest.submitted || rest.disabled ? " disabled" : "")
      }
      onClick={() => rest.onClick && rest.onClick(rest.value)}
      disabled={rest.disabled}
    >
      {rest.title}
    </button>
  );
}
