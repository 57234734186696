import React from "react";
import side from "../assets/img/bermuda-home.jpg";
import Button from "../components/commons/Button";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const navigate = useNavigate();

  return (
    <section className="bg-white h-screen w-full flex flex-col items-center mt-20">
      {/* <Header /> */}
      <div className="content mt-10">
        <img
          src={side}
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="relative z-10 text-center  ">
          <p className="mb-10 text-[#000064] font-bold text-3xl">Welcome!</p>
          <p className="mb-10  text-[#000064] font-bold text-xl">
            Test your knowledge on Bermuda for a chance to land a<br /> 90
            minute flight to paradise.
          </p>

          <p className="text-[#000064] font-bold text-xl">
            Win a trip to Bermuda!
          </p>
          <div className="flex items-center justify-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="398"
              height="337"
              viewBox="0 0 398 337"
              fill="none"
            >
              <path
                d="M3.5241 334.917L199 3.95523L394.476 334.917H3.5241Z"
                fill="#F499B8"
                stroke="#000064"
                strokeWidth="4.02287"
              />
              <text
                x="50%"
                y="30%"
                fill="#000064"
                fontFamily="Klarheit Grotesk"
                fontSize="40"
                fontWeight="800"
                lineheight="125% "
                textAnchor="middle"
                dominantBaseline="middle"
                style={{ letterSpacing: "-0.8px" }}
              >
                <tspan x="50%" dy="1.5em">
                  Win a
                </tspan>
                <tspan x="50%" dy="1.2em">
                  trip to
                </tspan>
                <tspan x="50%" dy="1.2em">
                  Bermuda!
                </tspan>
              </text>
            </svg>
          </div>
          <div className="mt-10">
            <Button
              title={"Start"}
              className="bg-blue-800 text-white text-lg border-btn w-1/2 flex justify-center"
              onClick={() => navigate("/register")}
            />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </section>
  );
};
