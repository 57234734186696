import React, { useEffect, useState } from "react";
import side from "../assets/img/bermuda-triangle-2.png";
import Button from "../components/commons/Button";
import LayoutHalfScreen from "../components/layout/LayoutHalfScreen";
import axios from "axios";
import Header from "../components/layout/Header";
import { Footer } from "react-daisyui";
import config from "../config/index";
import { useAtom } from "jotai";
import { triviaAtom } from "../App";
import { userAtom } from "../App";
import { useNavigate } from "react-router-dom";
import { isEmptyObject } from "../libraries/utils";

export const Facts = () => {
  const [trivia, setTrivia] = useAtom(triviaAtom);
  const [user, setUser] = useAtom(userAtom);
  const [alreadyAnswered, setAlreadyAnswered] = useState(false)
  const navigate = useNavigate();

  // const [triviaData, setTriviaData] = useState()

  useEffect(() => {
    if (isEmptyObject(user)) {
      navigate("/register");
      return;
    }
    getTrivia();
    answers();
  }, []);

  async function getTrivia() {
    let triviaId;

    if (currentHour >= 10 && currentHour < 12) triviaId = 187;
    if (currentHour >= 12 && currentHour < 16) triviaId = 226;
    if (currentHour >= 16 && currentHour < 18) triviaId = 228;
    if (currentHour >= 18 && currentHour < 20) triviaId = 229;
    if (currentHour >= 20 && currentHour < 22) triviaId = 230;

    try {
      const response = await axios.get(
        `https://solodns.net/test1/wp-json/wp/v2/trivia/${triviaId}`
      );
      // console.log(response.data)
      setTrivia(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const answers = async () => {
    const results = await fetch(
      "https://solodns.net/test1/wp-json/wp/v2/results-list"
    );
    console.log(results, user.id, "USERID");
    const userResponse = results.filter(
      (r) => r.acf.user_id === user.id && r.acf.trivia.id === trivia.id
    );
    if (userResponse[0]) {
      // El usuario ya respondió esta pregunta
      setAlreadyAnswered(true)
    }
  };

  console.log("USUARIO", user);

  const ahora = new Date();
  const currentHour = ahora.getHours();
  const currentMinutes = ahora.getMinutes();

  console.log("triviadata", trivia);

  return (
    <LayoutHalfScreen
      main={{
        className:
          "bg-gradient-to-r from-primary to-primary-focus md:from-white md:to-white mt-20",
      }}
      container={{ className: "rounded-t-[50px] px-8 pt-8 h-full" }}
      side={{
        className:
          "bg-fuchsia-400 flex items-center h-full justify-center px-10",
        imageClassName: "max-h-[50%] ",
        image: side,
      }}
    >
      {alreadyAnswered && answerDisclaimer}
      {!alreadyAnswered && !isEmptyObject(user) ? (
        <div className="bg-white h-screen w-full flex flex-col items-center">
          <h1 className="flex justify-center text-center mt-10 text-xl font-bold text-[#000064]">
            Bermuda Facts
          </h1>
          {!isEmptyObject(trivia) && (
            <div key={trivia.id}>
              <h1 className="my-10 text-center text-3xl text-[#000064]">
                {trivia.title.rendered}
              </h1>
              <div
                className="text-lg text-[#000064]"
                dangerouslySetInnerHTML={{ __html: trivia.content.rendered }}
              />
            </div>
          )}
          <Button
            title={"Next"}
            className="bg-blue-800 mt-10 text-white text-lg border-btn w-1/2 flex justify-center"
            onClick={() => navigate("/trivia/questions")}
          />
        </div>
      ) : (
        <p>No estas logeado</p>
      )}
    </LayoutHalfScreen>
  );
};

const answerDisclaimer = (
  <section className="bg-white h-screen w-full flex flex-col items-center mt-20">
    <div className="content mt-10">
      <div className="flex items-center justify-center ">
        <img
          src={side}
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="relative z-10 text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="398"
            height="337"
            viewBox="0 0 398 337"
            fill="none"
          >
            <path
              d="M3.5241 334.917L199 3.95523L394.476 334.917H3.5241Z"
              fill="#F499B8"
              stroke="#000064"
              strokeWidth="4.02287"
            />
            <text
              x="50%"
              y="30%"
              fill="#000064"
              fontFamily="Klarheit Grotesk"
              fontSize="26"
              fontWeight="800"
              textAnchor="middle"
              dominantBaseline="middle"
              style={{ letterSpacing: "-0.036px" }}
            >
              <tspan x="50%" dy="1.2em">
                You've
              </tspan>
              <tspan x="50%" dy="1.2em">
                already
              </tspan>
              <tspan x="50%" dy="1.2em">
                answered the
              </tspan>
              <tspan x="50%" dy="1.2em">
                trivia, please wait
              </tspan>
              <tspan x="50%" dy="1.2em">
                a few minutes to answer
              </tspan>
              <tspan x="50%" dy="1.2em">
                the next one.
              </tspan>
            </text>
          </svg>
        </div>
      </div>
    </div>
  </section>
);
