// import config from '../config';

export const capitalizePhrase = (string) => {
  if (typeof string !== "string") return "";
  return string
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");
};

export const isLandscape = () => {
  return window.innerWidth > window.innerHeight;
};

export const required = (value) => (value ? undefined : "Invalid");

export const mustBeNumber = (value) =>
  isNaN(value) ? "Must be a number" : undefined;

export const minValue = (min) => (value) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

export const maxValue = (max) => (value) =>
  isNaN(value) || value <= max ? undefined : `must be less than ${max}`;

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
export const acceptTerms = (value) =>
  value ? undefined : `You must agree to terms and conditions`;

export function validateEmail(email) {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email) ? undefined : "Email invalid";
}

export const formatOnlyNumberInput = (value, maxLength, extras = []) => {
  if (!value) return value;
  //const reg = RegExp(`(?![\\d+|\\${extras.join("")}]+)\\w+`);
  return value.replace(/\D+/g, "").slice(0, maxLength);
};

// export const getCurrentSlot = (dateTimeString) => {
//   const slots = config.SLOTS
//   const time = dateTimeString.substring(11, 19);

//   console.log("time", "slots")
//   console.log(slots, time)
//   const currentSlot = slots.find(slot =>
//     time >= slot.start && time <= slot.end
//   );

//   return currentSlot || null;
// }

export const fromISO = (dateISO, separator = "/") => {
  const parts = dateISO.substring(0, 10).split("-");
  return `${parts[2]}${separator}${parts[1]}${separator}${parts[0]}`;
};

export const isObject = obj => {
  if (Object.prototype.toString.call(obj) === '[object Object]') return true;
  return false;
};

export const isEmptyObject = obj => {
  if (typeof obj === 'undefined') {
    return true;
  } else if (isObject(obj)) {
    return Object.entries(obj).length === 0;
  }
  return false;
};
