import React from "react";

const Modal = ({ onClose, children }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center mt-20">
      <div
        className="relative bg-white px-16 py-6 rounded-lg shadow-lg opacity-95 w-820px h-598px overflow-auto"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.95)" }}
      >
        {" "}
        <button onClick={onClose} className="absolute top-0 right-0 mt-2 mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
          >
            <path
              d="M28.4375 9.28125L9.59342 28.1253"
              stroke="#000064"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M29 27.8447L10.1559 9.00064"
              stroke="#000064"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
