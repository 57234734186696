import { useEffect } from "react";
import { Form } from "react-final-form";
import side from "../assets/img/bermuda-home.jpg";
import request from "../libraries/request";
import Button from "../components/commons/Button";
import { useAtom } from "jotai";
import { userAtom } from "../App";
import { triviaAtom } from "../App";
import { triviaAnswerAtom } from "../App";
import { userAnswersAtom } from "../App";
import { useState } from "react";
import Header from "../components/layout/Header";
import { Footer } from "react-daisyui";
import { useNavigate } from "react-router-dom";

const Questions = () => {
  const navigate = useNavigate();

  const [trivia, setTrivia] = useAtom(triviaAtom);
  const [user, setUser] = useAtom(userAtom);
  const [loading, setLoading] = useState(false);
  const [userAnswer, setUserAnswer] = useState();
  const [answerd, setAnswered] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const newAnswer = await request(
        "https://solodns.net/test1/wp-json/wp/v2/results-list",
        "POST",
        {
          acf: {
            result: userAnswer, //true || false,
            user_id: user.id, //(id del usuario que jugó),
            trivia_id: trivia.id, //(id de la trivia respondida),
          },
          status: "publish",
        }
      );
      if (newAnswer) {
        // Todo salió bien, dar feedback al usuaruio
        setAnswered(true)
      }
      setLoading(false);
      console.log(newAnswer, "--------------NEWAnswer--------------");
    } catch (error) {
      console.error(error);
    }
  };

  const ahora = new Date();
  const currentHour = ahora.getHours();
  const currentMinutes = ahora.getMinutes();

  const handleAnswerClick = (isCorrect) => {
    if (isCorrect) {
      setUserAnswer(true);
    } else {
      setUserAnswer(false);
    }
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {user && trivia ? (
              <div className="bg-white h-screen w-full flex flex-col items-center mt-20">
                <>
                  <div key={trivia.id}>
                    <h1 className="my-10 font-bold text-center text-3xl text-[#000064]">
                      {trivia.title.rendered}
                    </h1>
                    <p className="text-3xl font-bold text-center mx-28 text-[#000064]">
                      {trivia.acf.primary}
                    </p>
                  </div>
                  <div className="flex flex-col space-y-4 mt-10">
                    <button
                      className="w-[480px] text-[#000064] border-2 shadow-md border-[#000064] px-4 py-2 rounded-md hover:bg-[#F499B8]"
                      onClick={() => handleAnswerClick(false)}
                    >
                      {trivia.acf.incorrect_answer_1}
                    </button>
                    <button
                      className=" text-[#000064] border-2  border-[#000064] px-4 py-2 rounded-md hover:bg-[#F499B8]"
                      onClick={() => handleAnswerClick(true)}
                    >
                      {trivia.acf.correct_answer}
                    </button>
                    <button
                      className="w-[480px] text-[#000064] border-2 shadow-md border-[#000064] px-4 py-2 rounded-md hover:bg-[#F499B8]"
                      onClick={() => handleAnswerClick(false)}
                    >
                      {trivia.acf.incorrect_answer_2}
                    </button>
                    <button
                      className="w-[480px] text-[#000064] border-2 shadow-md border-[#000064] px-4 py-2 rounded-md hover:bg-[#F499B8]"
                      onClick={() => handleAnswerClick(false)}
                    >
                      {trivia.acf.incorrect_answer_3}
                    </button>
                  </div>
                </>
                <Button
                  title={"Next"}
                  disabled={loading || answerd}
                  className="bg-[#000064] mt-10 text-white text-lg border-btn w-1/4 flex justify-center"
                  onClick={onSubmit}
                />
              </div>
            ) : (
              <p>Hubo un error intente nuevamente</p>
            )}
          </form>
        )}
      </Form>
      {answerd && <p>Respuesta recibida. Gracias por jugar.</p>}
    </div>
  );
};

export default Questions;
