import React from 'react';

export default function LayoutHalfScreen({ side, header, children, main, container }) {
  // side: { image: <image> } or content: <content>
  return (
    <main className={`w-full flex flex-wrap h-screen ${main && main.className ? main.className : ''}`}>
      <div className="w-full md:w-1/2 flex flex-col">
        {/* <Header header={header} /> */}
        <div className={`flex flex-col ${container && container.className ? container.className : ''} bg-white items-center md:justify-start md:pt-0 md:px-24 lg:px-32`}>
          {children}
        </div>
        {/* <FooterSmall /> */}
      </div>
      {side && (
        <div
          className={
            'w-1/2 shadow-2xl hidden md:flex ' +
            (side.className ? side.className : '')
          }
        >
          {side && side.image && (
            <img
              alt=""
              className={
                side.imageClassName
                  ? side.imageClassName
                  : 'object-cover w-full hidden md:block'
              }
              src={side.image}
            />
          )}
        </div>
      )}
    </main>
  );
}