import React from "react";
import side from "../assets/img/bermuda-home.jpg";
import Button from "../components/commons/Button";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { useNavigate } from "react-router-dom";
import { triviaAtom } from "../App";
import { useAtom } from "jotai";

import { triviaAnswerAtom } from "../App";


export const CorrectAns = () => {
  const navigate = useNavigate();

  const [triviaData, setTriviaData] = useAtom(triviaAtom);
  const [triviaAnswer, setTriviaAnswer] = useAtom(triviaAnswerAtom);
  console.log(triviaData, 'soy el answer')
  console.log(triviaAnswer.acf.result, 'Soy el answer')

  return (
    <section className="bg-white h-screen w-full flex flex-col items-center mt-20">
      {/* <Header /> */}
      <div className="content mt-10">
        <img
          src={side}
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="relative z-10 text-center ">
          {triviaAnswer.acf.result === true 
          ? 
            <p className="mb-10 text-[#000064] font-bold text-3xl">You’re In!</p>
          :  
            <p className="mb-10 text-[#000064] font-bold text-3xl">
              The correct answer is {triviaData.acf.correct_answer}.          
            </p>

          }
          <p className="mb-10  text-[#000064] font-bold text-xl">
            Thank you for participating. Look out for an email to see if
            <br /> you’re a lucky winner!
          </p>

          <div className="flex items-center justify-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="398"
              height="337"
              viewBox="0 0 398 337"
              fill="none"
            >
              <path
                d="M3.5241 334.917L199 3.95523L394.476 334.917H3.5241Z"
                fill="#F499B8"
                stroke="#000064"
                strokeWidth="4.02287"
              />
              <text
                x="50%"
                y="30%"
                fill="#000064"
                fontFamily="Klarheit Grotesk"
                fontSize="26"
                fontWeight="800"
                textAnchor="middle"
                dominantBaseline="middle"
                style={{ letterSpacing: "-0.036px" }}
              >
                <tspan x="50%" dy="1.2em">
                  Come
                </tspan>
                <tspan x="50%" dy="1.2em">
                  back in
                </tspan>
                <tspan x="50%" dy="1.2em" fill="#FFF">
                  00:00 minutes
                </tspan>
                <tspan x="50%" dy="1.2em">
                  for another trivia
                </tspan>
                <tspan x="50%" dy="1.2em">
                  and try again
                </tspan>
                {/* <tspan x="50%" dy="1.2em">
                  try again! Tap below to
                </tspan>
                <tspan x="50%" dy="1.2em">
                  experience our Bermuda
                </tspan>
                <tspan x="50%" dy="1.2em">
                  beach AR while waiting.
                </tspan> */}
                {/* <tspan
                  x="50%"
                  dy="1.2em"
                  fontWeight="600"
                  textDecoration="underline"
                >
                  Try AR
                </tspan> */}
              </text>
            </svg>
          </div>
          <div className="inline-flex flex-col items-center p-[28px_57px_32px_57px] gap-4 rounded-lg bg-[#000064] text-white w-[530px] h-[200px] mt-6">
            <p className="text-center font-['Klarheit_Grotesk'] text-[14px] font-medium leading-[20px] tracking-[-0.032em] m-0">
              Regardless of if you win or lose, you can still score
              <br />a 90 minute flight from NYC to Paradise.
            </p>
            <Button
              title="Book Now"
              className="w-[354px] h-[74px] text-[#000064] bg-white text-center font-['Klarheit_Grotesk'] text-[20px] font-bold leading-[25px] tracking-[-0.04em] rounded-lg p-0"
              onClick={() => console.log("Booking...")}
            />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </section>
  );
};
