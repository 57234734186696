import React from "react";
import { AppRoutes } from "./routes";
import { BrowserRouter } from "react-router-dom";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

export const userAtom = atomWithStorage("user", {});
export const userAnswersAtom = atom([]);
export const triviaAtom = atomWithStorage("trivia");
export const triviaAnswerAtom = atomWithStorage("triviaAnswer", {});

function App() {
  return (
    <BrowserRouter>
      <Header />
      <AppRoutes />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
