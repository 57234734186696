const index = {
    TESTING: true,
    PROD_TESTING: true,
    ROUTES: {
      HOME: "/",
      RULES: "/rules",
    },
    SLOTS: [
      {start: "14:30:00", end: "15:29:59", trivia_id: "1", p: "p1"},
      {start: "15:30:00", end: "16:29:59", trivia_id: "2", p: "p2"},
      {start: "16:30:00", end: "17:29:59", trivia_id: "3", p: "p3"},
      {start: "17:30:00", end: "18:29:59", trivia_id: "4", p: "p4"},
      {start: "18:30:00", end: "19:29:59", trivia_id: "5", p: "p5"},
      
    ],
    
  };
  export default index