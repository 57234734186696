import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import Register from "../pages/Register";
import { Facts } from "../pages/Facts";
import { CorrectAns } from "../pages/CorrectAns";
import Questions from "../pages/Questions";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Home />} />
      <Route path={"/register"} element={<Register />} />
      <Route path={"/trivia/facts"} element={<Facts />} />
      <Route path={"/trivia/questions"} element={<Questions />} />
      <Route path={"/trivia/answers"} element={<CorrectAns />} />
    </Routes>
  );
};
