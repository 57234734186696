import React from "react"

const TextInput = ({ input, meta, label, ...rest }) => {
  return (
    <div className={("form-control" + rest.className)}>
      {label && (
        <label
          htmlFor={input.name}
          className={("label" + rest.labelClassName)}
        >
          <span className={("label-text" + rest.textClassName)}>{label}</span>
        </label>
      )}
      <input
        {...input}
        type="  text"
        className={(
          "input w-full" + (meta?.error && meta?.touched ? "input-error invalid-shadow" : '')
          + rest.inputClassName
        )}
        onChange={e => {
          input.onChange(e) //final-form's onChange
          if (rest.onChange) {
            //props.onChange
            rest.onChange(e)
          }
        }}
        placeholder={rest.placeholder}
        disabled={rest.disabled}
        readOnly={rest.readOnly}
      />
      {!rest.noError && (
        <label className={"label" + rest.errorClassName}>
          <span className="label-text-alt invalid-feedback text-red-700">
            {meta?.error && meta?.touched && meta?.error}
          </span>
        </label>
      )}
    </div>
  )
}

export default TextInput
