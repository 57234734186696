const nickname = 'solodns.net'
const password = 'WX1G pQJC wY7z aMPt sZBX 6ULO'

export default async function(url = '', method = 'GET', body = {}) {
  const headers = { 
    'Content-Type' : 'application/json',
    'Authorization' : 'Basic ' + window.btoa(`${nickname}:${password}`)
  }
  let response;
  if (method != 'GET') {
    response  = await (await fetch(url, {
      method,
      headers,
      body: JSON.stringify(body)
    })).json()
  } else {
    response = await (await fetch(url, {
      method,
      headers
    })).json()
  }
  console.log(response);
  return await response;
}