import React from 'react';

const CheckboxInput = ({ input, meta, label, ...rest }) => {
  return (
    <div className={"form-control" + rest.className}>
      <div>
        <input
          {...input}
          type="checkbox"
          className={'checkbox bg-transparent border-[#000064]' + (rest.checkboxClassName && rest.checkboxClassName)}
          disabled={rest.readOnly || rest.disabled}
          id={input.name}
        />
        <span className="checkbox-mark"></span>
      </div>
      <div className=''>
        <label
          className={
            'cursor-pointer label  ' + (rest.labelClassName && rest.labelClassName)
          }
        >
          <span className="text-[#000064]">{label}</span>
        </label>
        {!rest.noError && meta?.error && meta?.touched && <p className="label-text-alt invalid-feedback text-red-700">{meta?.error}</p>
        }  
      </div>
    </div>
  );
};
export default CheckboxInput;
