import { Field, Form } from "react-final-form";
import TextInput from "../components/forms/TextInput";
import DatepickerInput from "../components/forms/DatepickerInput";
import {
  formatOnlyNumberInput,
  required,
  composeValidators,
  validateEmail,
  acceptTerms,
} from "../libraries/utils";
import CheckboxInput from "../components/forms/CheckboxInput";
import { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import request from "../libraries/request";
import { useAtom } from "jotai";
import { userAtom } from "../App";
import { useNavigate } from 'react-router-dom'
import Button from "../components/commons/Button";
import { isEmptyObject } from "../libraries/utils";

import { format } from "date-fns";

const Register = () => {
  const navigate = useNavigate()

  const [user, setUser] = useAtom(userAtom)
  const [loading, setLoading] = useState(false)

  console.log('________user', user)
  useEffect(()=>{
    if (!isEmptyObject(user)) {
      navigate('/trivia/facts')
    }
  }, [])

  const onSubmit = async values => {
    setLoading(true)
    try {
      const newUser = await request('https://solodns.net/test1/wp-json/wp/v2/trivia-user-example','POST', {
        acf: {
          name: values.name,
          email: values.email,
          zip_code: +values.zip,
          birthdate: format(values.birthdate, 'dd/MM/yyyy')
        },
        status: "publish"
      })
      console.log(newUser)
      if (newUser) {
        setUser(newUser)
        navigate('/trivia/facts')
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  return (
    <main className="flex flex-col h-screen">
      <Header />
      <section className="bg-white flex-1 w-full flex flex-col justify-center items-center text-[#000064]">
        <h1 className="text-4xl mb-5 font-semibold text-[#000064]">
          Enter your information
        </h1>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-center gap-4"
            >
              <Field
                inputClassName=" bg-transparent border-2 border-[#000064]"
                className=" flex flex-col"
                textClassName=" text-[#000064] font-semibold"
                name="name"
                label="Full Name*"
                placeholder="Full Name"
                component={TextInput}
                validate={required}
              />
              <Field
                inputClassName=" !bg-transparent border-2 border-[#000064]"
                className=" flex flex-col"
                textClassName=" text-[#000064] font-semibold"
                name="email"
                placeholder="acc@email.com"
                label="Email*"
                component={TextInput}
                validate={validateEmail}
              />
              <Field
                inputClassName=" bg-transparent border-2 border-[#000064]"
                className=" flex flex-col"
                textClassName=" text-[#000064] font-semibold"
                name="zip"
                label="Zip Code*"
                component={TextInput}
                placeholder={"Zip Code"}
                parse={(v) => formatOnlyNumberInput(v, 5)}
                validate={required}
              />
              <Field
                inputClassName=" bg-transparent"
                className=" flex flex-col "
                textClassName=" text-[#000064] font-semibold"
                name="birthdate"
                label="Date of birth*"
                placeholder="DD/MM/YYYY"
                component={DatepickerInput}
                validate={required}
              />
              <Field
                name="terms"
                component={CheckboxInput}
                label="I am over 18 years of age and by clicking this, I agree to the Terms and Conditions and the Privacy Policy.*"
                validate={acceptTerms}
                type="checkbox"
                className=" flex-row items-center"
              />
              <Field
                name="marketing"
                component={CheckboxInput}
                label="I agree to opt-in to receive marketing emails for promotion purposes."
                type="checkbox"
                className=" flex-row items-center"
              />
              <button
                type="submit"
                className="bg-[#000064] px-28 rounded-lg py-3 mx-auto font-bold text-white"
              >
                {loading ? <div className="animate-spin w-5 h-5 border-t-4 border-l-4 border-white rounded-full" /> : 'Next'}
              </button>
            </form>
          )}
        </Form>
      </section>
      <Footer />
    </main>
  );
};

export default Register;
